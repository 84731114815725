import {
  forwardRef,
  RadioGroup,
  RadioProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import RadioCard from '../../../../components/RadioCard';

type RadioMapProps = RadioProps & {
  array: Array<[string, Record<string, string> | string]>;
  value: string;
};

const RadioMap = forwardRef<RadioMapProps, 'input'>(
  ({ array, value, ...radioCardProps }, ref): React.ReactElement => (
    <RadioGroup
      as={SimpleGrid}
      columns={[1, null, 2]}
      gap={[1.5, null, null, 1.5]}
      mt={1.5}
      value={value}
      variant="darkBlue"
    >
      {array.map(([key, value]) => (
        <RadioCard
          isChecked={key === value}
          key={key}
          ref={ref}
          value={key}
          {...radioCardProps}
        >
          <Text fontWeight={500} textStyle="body5">
            {value.title || value}
          </Text>
          {value.description && (
            <Text color="darkBlue.40" textStyle="body6">
              {value.description}
            </Text>
          )}
        </RadioCard>
      ))}
    </RadioGroup>
  )
);

export default RadioMap;
