import React, { useEffect } from 'react';
import { Box, SimpleGrid, Input, Select, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { useFormContext } from 'react-hook-form';

import StepFormProps from '../StepFormProps';
import campuses from '../../../../../campus.json';
import { campusLookup, courseAndFormatLookup } from '../../../../../lib/utils';
import ErrorCard from '../ErrorCard';
import SubmitButton from '../SubmitButton';
import Terms from '../Terms';
import FormField from '../FormField';
import RadioMap from '../RadioMap';
import { useFilteredCohorts } from './models';

const Step2Form = ({
  onSubmitHandler,
  isSubmitting,
  submitError,
  ...props
}: StepFormProps): React.ReactElement => {
  const { t } = useTranslation();
  const { register, resetField, setValue, watch } = useFormContext();
  const [
    selectedType,
    selectedFormat,
    selectedCampus,
    selectedCourse,
    selectedCohort,
  ] = watch(['type', 'format', 'campus', 'course', 'cohort']) as string[];
  const [[remoteCampus], inCampuses] = _.partition(['code', 'rmt'], campuses);
  const [cohorts, noCohortsAvailable] = useFilteredCohorts(props.cohorts);

  const courseCampusKey =
    selectedCourse === 'cysec'
      ? 'course-cybersecurity-campus:cysec'
      : 'course-campus:courseCampus';

  const types = Object.entries(
    t('application-form:applicationForm.typeSelection.types', {
      returnObjects: true,
    })
  );
  const formats = Object.entries(
    t('application-form:applicationForm.formatSelection.formats', {
      returnObjects: true,
      ftDuration: t(`${courseCampusKey}.formats.fullTime.weeks`),
      ptDuration: t(`${courseCampusKey}.formats.partTime.weeks`),
    })
  );

  useEffect(() => {
    if (selectedType === 'remote') {
      setValue('campus', remoteCampus.code, { shouldValidate: true });
    }

    if (selectedType === 'inCampus') {
      resetField('campus');
    }

    resetField('format');
    resetField('cohort');
  }, [remoteCampus.code, resetField, selectedType, setValue]);

  return (
    <Box bg="white" borderRadius="card" p={5}>
      <FormField
        name="type"
        title={t('application-form:applicationForm.typeSelection.title')}
      >
        <RadioMap
          array={types}
          value={selectedType}
          {...register('type', {
            required: t('application-form:applicationForm.fieldsErrors.type'),
          })}
        />
      </FormField>
      {selectedType === 'inCampus' && (
        <FormField
          name="campus"
          title={t('application-form:applicationForm.fields.campus')}
        >
          <Select
            color="electricBlue.100"
            iconColor="darkBlue.100"
            iconSize="x-large"
            placeholder={t(
              'application-form:applicationForm.placeholders.campus'
            )}
            value={selectedCampus}
            {...register('campus', {
              required: t(
                'application-form:applicationForm.fieldsErrors.campus'
              ),
            })}
          >
            {inCampuses.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
        </FormField>
      )}
      <FormField
        name="format"
        title={t('application-form:applicationForm.formatSelection.title')}
      >
        <RadioMap
          array={
            selectedType &&
            Object.keys(cohorts).length === 1 &&
            !noCohortsAvailable
              ? formats.filter((format) => !_.isEmpty(cohorts[format[0]]))
              : formats
          }
          value={selectedFormat}
          {...register('format', {
            required: t('application-form:applicationForm.fieldsErrors.format'),
          })}
        />
      </FormField>
      {selectedType && selectedFormat && !_.isEmpty(cohorts[selectedFormat]) && (
        <FormField
          name="cohort"
          title={t('application-form:applicationForm.fields.course_date')}
        >
          <RadioMap
            array={cohorts?.[selectedFormat]}
            value={selectedCohort}
            {...register('cohort', {
              required: t(
                `application-form:applicationForm.fieldsErrors.cohort`
              ),
            })}
          />
        </FormField>
      )}
      {noCohortsAvailable && (
        <>
          <ErrorCard
            message={t(`application-form:applicationForm.noCohorts`, {
              course: courseAndFormatLookup(selectedCourse + selectedFormat),
              campus: campusLookup(selectedCampus),
            })}
            mt={4}
          />
          <Text mt={3} textStyle="title2">
            {t('application-form:applicationForm.noCohortsTitle')}
          </Text>
          <SimpleGrid columns={[1, null, 2]} spacing={4}>
            <FormField
              name="firstName"
              title={t('application-form:applicationForm.fields.firstName')}
            >
              <Input
                placeholder={t(
                  'application-form:applicationForm.placeholders.firstName'
                )}
                type="text"
                {...register('firstName', {
                  required: t(
                    'application-form:applicationForm.fieldsErrors.firstName'
                  ),
                })}
              />
            </FormField>
            <FormField
              name="lastName"
              title={t('application-form:applicationForm.fields.lastName')}
            >
              <Input
                placeholder={t(
                  'application-form:applicationForm.placeholders.lastName'
                )}
                type="text"
                {...register('lastName', {
                  required: t(
                    'application-form:applicationForm.fieldsErrors.lastName'
                  ),
                })}
              />
            </FormField>
          </SimpleGrid>
        </>
      )}
      {submitError && (
        <ErrorCard message={t('application-form:applicationForm.error')} />
      )}
      <SubmitButton
        isSubmitting={isSubmitting}
        onSubmit={(): void =>
          onSubmitHandler(noCohortsAvailable ? undefined : 2)
        }
        text={
          noCohortsAvailable
            ? t('application-form:applicationForm.noCohortsCta')
            : t('application-form:applicationForm.cta.continue')
        }
      />
      <Terms />
    </Box>
  );
};

export default Step2Form;
