import React, { useState } from 'react';
import { Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import Bugsnag from '@bugsnag/js';
import * as qs from 'query-string';
import _ from 'lodash/fp';

import { Logo, Stats } from '../experiments';
import { replaceValues } from '../../../lib/utils';
import CompanyShowcase from '../../CompanyShowcase';
import { DesktopLayout, MobileLayout } from './Components';
import TCohort from '../../../components/interfaces/Cohorts';
import useUserLocation from '../../../hooks/useUserLocation';
import { SaveApplicationData } from '../../../storage/provider';
import { getGaConnectorValues } from '../../../lib/ga-connector';
import { getLinkByPageName } from '../../../lib/links';
import * as gaEvents from './ga_events';

const FORM_EMPTY_VALUES = {
  campus: null,
  cohort: null,
  course: null,
  date: null,
  email: null,
  firstName: null,
  format: null,
  interest: null,
  isGoogle: false,
  lastName: null,
  newsletter: false,
  phoneCountry: null,
  phoneNumber: null,
  salesforceId: null,
  termsOfUse: true,
  timezone: null,
  type: null,
};

const ApplyWithCohort = (props: { cohort: TCohort }): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userLocation } = useUserLocation();
  const location = useLocation();
  const search = qs.parse(location.search);

  const cohort = props.cohort;
  const [formStep, setFormStep] = useState<1 | 2>(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [salesforceData, setSalesforceData] = useState<{
    id: string;
    type: string;
  }>({});

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: replaceValues(FORM_EMPTY_VALUES, {
      campus: cohort.campus_code,
      cohort: cohort.id,
      course: cohort.course_code.slice(0, -2),
      date: cohort.start_at,
      format: cohort.course_code.slice(-2),
      remote: cohort.campus_code === 'rmt',
      salesforceId: cohort.salesforce_id,
      timezone: cohort.timezone,
    }),
  });

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const handleSubmit = async (): Promise<void> => {
    const values = methods.getValues();

    const fields =
      formStep === 1 ? ['email'] : ['firstName', 'lastName', 'phoneNumber'];

    const isValid = await methods.trigger(fields, {
      shouldFocus: true,
    });

    if (!isValid) return;

    SaveApplicationData(values);

    const nurturingActions = [];
    if (formStep === 2) {
      if (location?.state?.form?.applySource === 'shortCourseJsThanks')
        nurturingActions.push('SCWD Direct Apply');
      if (values.isGoogle) nurturingActions.push('GoogleApply');
    } else {
      nurturingActions.push('Apply Step 2');
    }

    const applicant = {
      apply: {
        email_opt_in: values.newsletter,
        ga_connector: { ...getGaConnectorValues(document.cookie || '') },
        ip_country: userLocation.countryName,
        nurturing_actions: _.isEmpty(nurturingActions)
          ? undefined
          : nurturingActions,
        phone_country: values.phoneCountry,
        sf_object_id: salesforceData.id,
        sf_object_type: salesforceData.type,
      },
      cohort: values.cohort,
      cohort_campus: cohort.campus_code,
      email: values.email,
      first_name: values.firstName,
      language,
      last_name: values.lastName,
      phone: values.phoneNumber,
      timezone: values.timezone,
    };

    const baseUrl = process.env.IRONHACK_API?.slice(0, -8) as string;
    try {
      setHasSubmitError(false);
      setIsSubmitting(true);
      const response = await fetch(`${baseUrl}/applicants`, {
        body: JSON.stringify(applicant),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      setIsSubmitting(false);

      if (response.status >= 400 && response.status < 600) {
        throw new Error('Could not complete application');
      }

      if (values.isGoogle) {
        gaEvents.applyWithGoogleOk(cohort.campus_code, cohort.course_code);
      } else {
        gaEvents.applyWithEmailOk(cohort.campus_code, cohort.course_code);
      }

      const applyResult = await response.json();
      if (formStep === 2) {
        await navigate(
          getLinkByPageName(
            'allCourses/application/applicationThanks',
            language
          ),
          {
            state: {
              instapageLandingId: search.lp_id,
              applicant: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                cohort: values.cohort,
                sfObjectId: applyResult.result.id,
                sfObjectType: applyResult.result.type,
                hasAccount: applyResult.result.has_psp_account,
                allowAccountCreation:
                  (cohort.campus_code === 'rmt' || cohort.is_offering) &&
                  ((cohort.timezone === 'Europe/Madrid' &&
                    cohort.language === 'en') ||
                    cohort.timezone === 'America/Chicago'),
              },
            },
          }
        );
      } else {
        setFormStep(2);
        setSalesforceData(applyResult.result);
      }
    } catch (error) {
      setHasSubmitError(true);
      Bugsnag.notify(error, function (event) {
        event.addMetadata('Query', applicant);
      });
    }
  };

  const layoutProps = {
    cohort,
    formStep,
    hasSubmitError,
    isSubmitting,
    onSubmit: handleSubmit,
  };

  return (
    <Box bg="purple.10" minH="100vh">
      <Container
        maxW="1280px"
        position="relative"
        pt={[0, null, null, 6]}
        px={0}
      >
        <FormProvider {...methods}>
          <form>
            {!isMobile && (
              <Box position="absolute">
                <Logo size="l" />
              </Box>
            )}
            <Flex justifyContent="center">
              {isMobile ? (
                <MobileLayout {...layoutProps} />
              ) : (
                <DesktopLayout {...layoutProps} />
              )}
            </Flex>
            <Box mt={4}>
              <Stats {...props} />
            </Box>
            <CompanyShowcase
              companies={props.data.datoCmsCompanyShowcase.btob}
              mt={2}
              my={0}
              p={0}
              title={t('home:home.companies.title')}
            />
          </form>
        </FormProvider>
      </Container>
    </Box>
  );
};

export { ApplyWithCohort };
