import React, { forwardRef } from 'react';
import { Box, Center, VStack, Text, useStyles, useTab } from '@chakra-ui/react';

import { Check } from '../../icons';

export type StepProps = {
  checked?: boolean;
  label: string;
  step: number;
  mobileLabel: string;
};

export const Step = forwardRef<HTMLDivElement, StepProps>(
  (props, ref): React.ReactElement => {
    const { checked, label, step } = props;
    const tabProps = useTab({ ...props, ref });
    const isSelected = Boolean(tabProps['aria-selected']);
    const styles = useStyles();

    return (
      <Box {...tabProps} __css={styles.tab} flexGrow={1} px={[2, null, 4]}>
        <VStack spacing={0.5}>
          <Center
            bg={isSelected ? 'darkBlue.80' : 'purple.20'}
            borderRadius="full"
            boxSize="2.4rem"
            color="white"
            fontSize={1}
            fontWeight="700"
          >
            {checked ? <Check boxSize="12px" /> : step}
          </Center>
          <Text
            color={isSelected ? 'darkBlue.80' : 'purple.20'}
            fontWeight="700"
            textAlign="center"
            textStyle="body5"
            whiteSpace="nowrap"
          >
            {label}
          </Text>
        </VStack>
      </Box>
    );
  }
);
