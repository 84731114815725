import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Stat, StatHelpText, StatNumber } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import _ from 'lodash';
import Markdown from 'react-markdown';
import { PageProps } from 'gatsby';

import { Star } from '../../../icons/Star';
import { processDatoCmsAssests } from '../../../services/datoUtilities';
import { useApplyForm } from '../../../hooks/datoCMS/pages/apply-form';
import { TDatoCmsGraphQlAssets } from '../../../types';
import Cohorts from '../../../components/interfaces/Cohorts';
import { PageContext } from '../../../context/PageContext';

type ApplicationData = {
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
  cohorts: Cohorts[];
};

type ApplicationProps = PageProps<ApplicationData, PageContext>;

const Stats = (props: ApplicationProps): React.ReactElement => {
  const assets = processDatoCmsAssests(props.data.allDatoCmsAssetsByPage);
  const {
    i18n: { language },
  } = useTranslation();
  const content = useApplyForm(language);

  return (
    <Center as="section" color="darkBlue.64" textStyle="body4">
      <Box w="56px">
        <GatsbyImage
          alt={assets.switchup.alt}
          image={assets.switchup.gatsbyImageData}
          title={assets.switchup.title}
        />
      </Box>
      <Box w="70px">
        <GatsbyImage
          alt={assets.coursereport.alt}
          image={assets.coursereport.gatsbyImageData}
          title={assets.coursereport.title}
        />
      </Box>
      <Box ml={1} mt={2}>
        <Stat color="darkBlue.100">
          <StatNumber fontFamily="accent" fontSize={4}>
            {'4.9/5'}
            {_.range(5).map(
              (num: number): React.ReactElement => (
                <Star
                  boxSize="1.5rem"
                  fill="yellow.100"
                  key={num}
                  mb={0.5}
                  ml={0.5}
                />
              )
            )}
          </StatNumber>
          <StatHelpText fontSize={0} maxW="23ch" mt={0.5} textStyle="body6">
            <Markdown>{content.statLabel}</Markdown>
          </StatHelpText>
        </Stat>
      </Box>
    </Center>
  );
};

export default Stats;
