import React from 'react';
import _ from 'lodash/fp';
import {
  Box,
  forwardRef,
  RadioProps,
  useRadio,
  useRadioGroupContext,
  useStyleConfig,
} from '@chakra-ui/react';

const callAll = (fns) => (arg) => fns.forEach((fn) => fn?.(arg));

const RadioCard = forwardRef<RadioProps, 'input'>((props, ref) => {
  const { children, isChecked, onBlur, onChange: onChangeProp, value } = props;
  const group = useRadioGroupContext();

  const checked = group?.value ? group.value === value : isChecked;

  const { getInputProps, getCheckboxProps } = useRadio({
    ...props,
    isChecked: checked,
    defaultChecked: checked,
    name: _.getOr(group?.name, 'name', props),
    onChange: callAll([onChangeProp, group?.onChange]),
  });

  const styles = useStyleConfig('RadioCard', { variant: group?.variant });

  return (
    <Box as="label">
      <input {...getInputProps({}, ref)} />
      <Box __css={styles} {...getCheckboxProps()} onBlur={onBlur}>
        {children}
      </Box>
    </Box>
  );
});

export default RadioCard;
