import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import Bugsnag from '@bugsnag/js';
import { useLocation } from '@reach/router';
import * as qs from 'query-string';
import { Link as GatsbyLink, navigate, PageProps } from 'gatsby';

import { Stepper, StepProps } from '../../../components/Stepper';
import { SaveApplicationData } from '../../../storage/provider';
import { getGaConnectorValues } from '../../../lib/ga-connector';
import { getClientId } from '../../../lib/experiments';
import {
  getHomeStepApplicationMutation,
  getStepApplicationMutation,
  getUpdatesApplyMutation,
} from '../../../lib/graphql';
import * as gaEvents from '../../../template/Application/ga_events';
import { getLinkByPageName } from '../../../lib/links';
import useUserLocation from '../../../hooks/useUserLocation';
import Cohort from '../../../components/interfaces/Cohorts';
import {
  getStepApplicationPardotParams,
  getUpdatesApplyPardotParams,
  SalesforceApplicationInterface,
  SalesforceStepApplicationInterface,
} from '../../../lib/salesforce-integration';
import { TDatoCmsGraphQlAssets } from '../../../types';
import { PageContext } from '../../../context/PageContext';
import Step1Form from './StepForms/Step1Form';
import Step2Form from './StepForms/Step2Form';
import Step3Form from './StepForms/Step3Form';
import Details from './Details';
import { replaceValues } from '../../../lib/utils';

const FORM_EMPTY_VALUES = {
  campus: null,
  cohort: null,
  course: null,
  email: null,
  firstName: null,
  format: null,
  interest: null,
  lastName: null,
  newsletter: null,
  phoneCountry: null,
  phoneNumber: null,
  referral: null,
  referralOther: null,
  referrerCode: null,
  termsOfUse: true,
  type: null,
  whyAreYouInterested: null,
};

const DEFAULT_COURSE = 'web';

type FormsData = {
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
  cohorts: Cohort[];
  step?: number;
};

type FormsProps = PageProps<FormsData, PageContext>;

const Forms = (props: FormsProps): React.ReactElement => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: replaceValues(FORM_EMPTY_VALUES, props.defaultValues),
  });
  const { reset, watch } = methods;
  const location = useLocation();
  const search = qs.parse(location.search);
  const { userLocation } = useUserLocation();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const [cohortId, campus] = watch(['cohort', 'campus']);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cohort, setCohort] = useState(null);
  const [stepIndex, setStepIndex] = useState(props.step || 0);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [showGetUpdatesSuccess, setShowGetUpdatesSuccess] = useState(false);

  const homeApplyForm = location?.state?.form?.applySource === 'home';

  useEffect(() => {
    if (cohortId)
      setCohort(
        props.cohorts.find((c) => c.id === cohortId && c.campus_code === campus)
      );
  }, [cohortId, props.cohorts]);

  useEffect(() => {
    const campusVisited =
      userLocation.campusVisited || userLocation.defaultCampus;
    const courseVisited =
      localStorage?.getItem('courseVisited')?.slice(0, -2) || DEFAULT_COURSE;
    const typeVisited = userLocation.campusVisited ? 'inCampus' : 'remote';

    reset(
      replaceValues(
        FORM_EMPTY_VALUES,
        {
          campus: campusVisited,
          course: courseVisited,
          type: typeVisited,
          financingOption: search.fo || null,
          referral: search.code ? 'other' : null,
          referralOther: search.code,
          referrerCode: search.r,
        },
        props.defaultValues
      )
    );
  }, [language, reset, search.code, search.fo, userLocation]);

  const fieldsToValidate = (step: number): string[] => {
    const firstStepFields = ['course', 'email', 'newsletter'];
    const secondStepFields = ['type', 'format', 'campus', 'cohort'];
    const thirdStepFields = [
      'firstName',
      'lastName',
      'phoneNumber',
      'phoneCountry',
      'whyAreYouInterested',
    ];

    const stepFields: Record<number, string[]> = {
      1: firstStepFields,
      2: [...firstStepFields, ...secondStepFields],
      3: [...firstStepFields, ...secondStepFields, ...thirdStepFields],
    };

    return stepFields[step];
  };

  const submitStepForm = async (nextStepIndex: number): Promise<void> => {
    const values = methods.getValues();
    const step = nextStepIndex;

    const fields = fieldsToValidate(step);

    const isValid = await methods.trigger(fields, {
      shouldFocus: true,
    });

    if (!isValid) return;

    SaveApplicationData(values);

    const { campus, course } = values;

    const selectedCohort = props.cohorts.find(
      (cohort) => cohort.id === values.cohort
    );

    const salesforceParameters: Partial<SalesforceStepApplicationInterface> = {
      campus,
      cohort: values.cohort,
      course,
      date: selectedCohort?.start_at,
      email: values.email,
      emailOptIn: values.newsletter,
      financingOption: values.financingOption,
      firstName: values.firstName,
      format: values.format,
      gaConnector: getGaConnectorValues(document.cookie || ''),
      ipCountry: userLocation.countryName,
      lastName: values.lastName,
      phoneCountry: values.phoneCountry,
      phoneNumber: values.phoneNumber,
      referral: values.referral,
      referralOther: values.referralOther,
      referrerCode: values.referrerCode,
      remote: values.type === 'rmt' && values.type,
      salesforceId: selectedCohort?.salesforce_id,
      whyAreYouInterested: values.interest,
    };
    const pardotParams = getStepApplicationPardotParams(
      salesforceParameters,
      language,
      step,
      selectedCohort?.timezone
    );
    const clientId = await getClientId(2000);
    const queryHomeApply = getHomeStepApplicationMutation(
      step,
      pardotParams,
      clientId
    );
    const queryStepApply = getStepApplicationMutation(
      step,
      pardotParams,
      clientId
    );

    try {
      let response: Promise<Response>;
      if (homeApplyForm && step === 3) {
        response = await fetch(process.env.IRONHACK_API, queryHomeApply);
      }

      if (!homeApplyForm) {
        response = await fetch(process.env.IRONHACK_API, queryStepApply);
      }

      if (response?.errors) throw response.errors;
      if (response && !response.ok) throw new Error(response.statusText);
      homeApplyForm
        ? gaEvents.emitApplyHomeStepOKEvent(step)
        : gaEvents.emitApplyStepOKEvent(step);
      setSubmitError(false);

      if (step === 3) {
        if (values.referrerCode && typeof window.prefinery === 'function')
          window.prefinery('addUser', {
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            referred_by: values.referrerCode,
          });
        await navigate(
          getLinkByPageName(
            'allCourses/application/applicationThanks',
            language
          ),
          { state: { instapageLandingId: search.lp_id } }
        );
      } else {
        setStepIndex(nextStepIndex);
      }
    } catch (error) {
      Bugsnag.notify(error, function (event) {
        event.addMetadata(
          'Query',
          homeApplyForm ? queryHomeApply : queryStepApply
        );
      });
      gaEvents.emitApplyKoEvent(campus, course);
    }
  };

  const submitUpdateForm = async (): Promise<void> => {
    const values = methods.getValues();
    const { campus, course, email, firstName, format, lastName } = values;

    const firstStepFields = ['course', 'email'];
    const secondStepFields = [
      'type',
      'format',
      'campus',
      'firstName',
      'lastName',
    ];
    const fields = [...firstStepFields, ...secondStepFields];
    const isValid = await methods.trigger(fields, {
      shouldFocus: true,
    });

    if (!isValid) {
      return;
    }

    const salesforceParameters: Partial<SalesforceApplicationInterface> = {
      campus,
      course: `${course}${format}`,
      email,
      emailOptIn: true,
      firstName,
      gaConnector: getGaConnectorValues(document.cookie || ''),
      lastName,
      ipCountry: userLocation.countryName,
    };
    const clientId = await getClientId(2000);
    const pardotParams = getUpdatesApplyPardotParams(
      salesforceParameters,
      language
    );
    const query = getUpdatesApplyMutation(pardotParams, clientId);
    await fetch(process.env.IRONHACK_API, query);
    setShowGetUpdatesSuccess(true);
  };

  const onSubmitHandler = async (nextStepIndex?: number): Promise<void> => {
    setIsSubmitting(true);
    if (submitError) setSubmitError(false);

    const submitHandler = nextStepIndex
      ? () => submitStepForm(nextStepIndex)
      : submitUpdateForm;

    try {
      await submitHandler();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setSubmitError(true);
    }
  };

  const stepsLabels = Object.values(
    t('application-form:applicationForm.stepper.labels', {
      returnObjects: true,
    })
  );

  const steps = stepsLabels.map((key, index): StepProps => {
    const step = index + 1;
    return {
      label: key,
      mobileLabel: t('application-form:applicationForm.stepper.counter', {
        step,
        totalSteps: stepsLabels.length,
      }),
      step,
      checked: false,
    } as StepProps;
  });

  if (showGetUpdatesSuccess) {
    return (
      <Box
        as="article"
        bg="white"
        borderRadius={[null, null, 'sm']}
        boxShadow={[null, null, 'active2']}
        gridColumnStart={[1, null, 2]}
        mr={[null, null, 6, 8]}
        p={6}
      >
        <Text mb={1.5} textAlign="center" textStyle="title2">
          {t('application-form:applicationForm.noCohortsSuccessTitle')}
        </Text>
        <Text mb={4} textAlign="center" textStyle="body3">
          {t('application-form:applicationForm.noCohortsSuccessBody')}
        </Text>
        <Button as={GatsbyLink} to={`/${language}/blog`} w="100%">
          {t('application-form:applicationForm.noCohortsSuccessCta')}
        </Button>
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <form>
        <Stepper
          details={
            <Details
              cohort={cohort}
              hideCart={Number(props.variant) ? stepIndex === 0 : true}
              textStyle="body5"
              w={['100%', null, 'auto']}
            />
          }
          stepIndex={stepIndex}
          steps={steps}
        >
          <Step1Form
            isSubmitting={isSubmitting}
            onSubmitHandler={onSubmitHandler}
            submitError={submitError}
            {...props}
          />
          <Step2Form
            isSubmitting={isSubmitting}
            onSubmitHandler={onSubmitHandler}
            submitError={submitError}
            {...props}
          />
          <Step3Form
            isSubmitting={isSubmitting}
            onSubmitHandler={onSubmitHandler}
            submitError={submitError}
            {...props}
          />
        </Stepper>
      </form>
    </FormProvider>
  );
};

export default Forms;
