import React from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';
import * as R from 'ramda';

import { TDatoCmsGraphQlAssets } from '../../../types';
import { PageContext } from '../../../context/PageContext';
import CompanyShowcase from '../../../containers/CompanyShowcase';
import { Forms, Logo, Stats } from '../../../containers/ApplyForm/experiments';
import { ApplyWithCohort } from '../../../containers/ApplyForm/ApplyWithCohort';
import Cohorts from '../../../components/interfaces/Cohorts';
import { PageDataProvider } from '../../../context/PageData';

type ApplicationData = {
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
  cohorts: Cohorts[];
};

type ApplicationProps = PageProps<ApplicationData, PageContext>;

const Application = (props: ApplicationProps): React.ReactElement => {
  const { t } = useTranslation();
  const { cohorts } = props.pageContext;
  const { location, variant } = props;
  const search = qs.parse(location.search);
  const cohortId = search.cohort || location.state?.form?.id;
  let cohort;
  if (cohortId) {
    const matcher = R.reject(R.isNil, {
      id: cohortId,
      campus_code: location.state?.form?.sourceCampus,
    });
    cohort = cohorts.find(R.whereEq(matcher));
  }

  const defaultValues = cohort
    ? {
        campus: cohort.campus_code,
        cohort: cohort.id,
        course: cohort.course_code.slice(0, -2),
        format: cohort.course_code.slice(-2),
      }
    : {};

  const formStep = Number(variant) && cohort ? 2 : 0;

  if (cohort) {
    return (
      <PageDataProvider data={props.data.allDatoCmsApplyForm.nodes[0]}>
        <ApplyWithCohort cohort={cohort} {...props} />;
      </PageDataProvider>
    );
  }

  return (
    <Box bg="purple.10" minHeight="100vh" padding={[0.5, null, 4, 5]}>
      <Box mt={2} pos={['static', null, 'absolute']}>
        <Logo size="m" />
      </Box>
      <Grid
        gap={3}
        marginX="auto"
        maxWidth="1112px"
        templateColumns={['repeat(6, 1fr)', null, 'repeat(12, 1fr)']}
      >
        <GridItem
          colEnd={[7, null, 'auto']}
          colStart={[1, null, 6, 9]}
          rowEnd={5}
          rowStart={1}
        >
          <Forms
            cohorts={cohorts}
            defaultValues={defaultValues}
            step={formStep as number}
            variant={variant}
            {...props}
          />
        </GridItem>
        <GridItem colEnd={[7, null, 12]} colStart={[1, null, 0]}>
          <Stats {...props} />
        </GridItem>
        <GridItem colEnd={[7, null, 12]} colStart={[1, null, 0]}>
          <CompanyShowcase
            companies={props.data.datoCmsCompanyShowcase.btob}
            my={0}
            p={[1, null, 0]}
            title={t('home:home.companies.title')}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Application;
