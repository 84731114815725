import React from 'react';
import { Box, BoxProps, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type CompanyShowcaseProps = {
  companies: Array<{
    name: string;
    logo: { alt?: string; gatsbyImageData: IGatsbyImageData[]; title?: string };
  }>;
  title: string;
} & BoxProps;

export default function CompanyShowcase(
  props: CompanyShowcaseProps
): JSX.Element {
  const { companies, title, ...boxProps } = props;

  return (
    <Flex
      alignItems="center"
      direction="column"
      maxW={[null, null, null, '1120px']}
      mx={[null, null, null, 'auto']}
      my={[4, null, 6]}
      p={[3, null, 4]}
      px={[null, null, null, 0]}
      {...boxProps}
    >
      <Text as="h3" color="darkBlue.64" textStyle="body5">
        {title}
      </Text>
      <SimpleGrid
        alignItems="center"
        columns={[4, null, null, companies.length]}
        mt={[1, null, null, 2]}
        spacing={2}
        w="100%"
      >
        {companies.map((company) => (
          <Box
            key={company.name}
            mx="auto"
            sx={{
              filter: 'grayscale(1)',
              _hover: {
                filter: 'grayscale(0)',
              },
            }}
            w={['50%', null, null, '100%']}
          >
            <GatsbyImage
              alt={company.logo.alt}
              image={company.logo.gatsbyImageData}
              title={company.logo.title}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
}
