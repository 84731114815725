import React, { useReducer, useEffect } from 'react';
import {
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  chakra,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { Step, StepProps } from './Step';

enum StepperTypesKind {
  CHECK_STEP = 'CHECK_STEP',
}

type StepperAction = {
  type: StepperTypesKind;
  index: number;
};

export type StepperState = {
  currentStepIndex: number;
  steps: StepProps[];
};

type StepperProps = {
  children: React.ReactNode;
  details: React.ReactElement;
  steps: StepProps[];
  stepIndex: number;
};

const reducer = (
  stepper: StepperState,
  action: StepperAction
): StepperState => {
  const types = {
    [StepperTypesKind.CHECK_STEP]: (): StepperState => ({
      currentStepIndex: action.index,
      steps: stepper.steps.map((step, i) =>
        i === stepper.currentStepIndex ? { ...step, checked: true } : step
      ),
    }),
  };

  return types[action.type]();
};

export const Stepper = ({
  children,
  details,
  steps,
  stepIndex,
  ...rest
}: StepperProps): React.ReactElement => {
  const [stepper, dispatch] = useReducer(reducer, {
    currentStepIndex: stepIndex,
    steps,
  });

  useEffect(() => {
    if (stepIndex !== stepper.currentStepIndex) {
      dispatch({ type: StepperTypesKind.CHECK_STEP, index: stepIndex });
    }
  }, [stepIndex, stepper.currentStepIndex]);

  const StyledTab = chakra('div', { themeKey: 'Tabs.Tab' });

  return (
    <Tabs
      as={Grid}
      columnGap={2}
      display="grid"
      index={stepIndex}
      isLazy
      rowGap={[1, null, 0]}
      templateColumns="repeat(2, auto)"
      templateRows="repeat(2, auto)"
      variant="unstyled"
      {...rest}
    >
      <TabList as={GridItem} colSpan={[2, null, 1]}>
        {stepper.steps.map(({ checked, label, step }) => (
          <Step
            _focus={{ boxShadow: 'none' }}
            as={StyledTab}
            checked={checked || step - 1 < stepIndex}
            key={label}
            label={label}
            step={step}
          />
        ))}
      </TabList>
      <TabPanels
        as={GridItem}
        colSpan={[2, null, 1]}
        gridRow={[null, null, 2]}
        minW={[null, null, '50rem', '54rem']}
        order={[1, null, 0]}
      >
        {React.Children.map(children, (child, index) => (
          <TabPanel key={`Step-Panel-${index}`} p={0}>
            {React.cloneElement(child)}
          </TabPanel>
        ))}
      </TabPanels>
      <GridItem
        gridColumn={['span 2', null, 2]}
        gridRow={[null, null, 2]}
        minW="24rem"
      >
        {details}
      </GridItem>
    </Tabs>
  );
};
