import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from '@reach/router';
import * as qs from 'query-string';

import StepFormProps from '../StepFormProps';
import ErrorCard from '../ErrorCard';
import SubmitButton from '../SubmitButton';
import Terms from '../Terms';
import FormField from '../FormField';
import { InputPhone } from '../../../../../components/Forms';
import { isValidPhone } from '../../../../../components/Forms/fields/_helpers';

const Step3Form = ({
  onSubmitHandler,
  isSubmitting,
  submitError,
}: StepFormProps): React.ReactElement => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const location = useLocation();
  const search = qs.parse(location.search);
  const [selectedReferral, selectedPhoneNumber] = watch([
    'referral',
    'phoneNumber',
  ]);

  const referralOpts = t(
    'application-form:applicationForm.fieldsValues.referral',
    {
      returnObjects: true,
    }
  );
  const interestOpts = t(
    'application-form:applicationForm.fieldsValues.whyAreYouInterested',
    {
      returnObjects: true,
    }
  );

  return (
    <Box bg="white" borderRadius="card" p={5}>
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={[2, null, 1]}>
          <FormField
            mt={0}
            name="firstName"
            title={t('application-form:applicationForm.fields.firstName')}
          >
            <Input
              placeholder={t(
                'application-form:applicationForm.placeholders.firstName'
              )}
              type="text"
              {...register('firstName', {
                required: t(
                  'application-form:applicationForm.fieldsErrors.firstName'
                ),
              })}
            />
          </FormField>
        </GridItem>
        <GridItem colSpan={[2, null, 1]}>
          <FormField
            mt={0}
            name="lastName"
            title={t('application-form:applicationForm.fields.lastName')}
          >
            <Input
              placeholder={t(
                'application-form:applicationForm.placeholders.lastName'
              )}
              type="text"
              {...register('lastName', {
                required: t(
                  'application-form:applicationForm.fieldsErrors.lastName'
                ),
              })}
            />
          </FormField>
        </GridItem>
        <GridItem colSpan={[2, null, 1]}>
          <FormField
            mt={0}
            name="email"
            title={t('application-form:applicationForm.fields.email')}
          >
            <Input
              placeholder={t(
                'application-form:applicationForm.placeholders.email'
              )}
              type="email"
              {...register('email', {
                required: t(
                  'application-form:applicationForm.fieldsErrors.email'
                ),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t(
                    'application-form:applicationForm.fieldsErrors.email'
                  ),
                },
              })}
            />
          </FormField>
        </GridItem>
        <GridItem colSpan={[2, null, 1]}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }): React.ReactElement => (
              <FormField
                mt={0}
                name="phoneNumber"
                title={t('application-form:applicationForm.fields.phoneNumber')}
              >
                <InputPhone
                  id="phoneNumber"
                  isValid={isValidPhone(value, errors.phoneNumber)}
                  onChange={(phone, country): void => {
                    setValue('phoneCountry', country.name);
                    onChange(phone);
                  }}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.phoneNumber'
                  )}
                  value={value}
                />
              </FormField>
            )}
            rules={{
              required: t(
                'application-form:applicationForm.fieldsErrors.phoneNumber'
              ),
              validate: () =>
                isValidPhone(selectedPhoneNumber) ||
                t('application-form:applicationForm.fieldsErrors.phoneNumber'),
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <FormField
            isRequired={false}
            mt={0}
            name="referral"
            title={`${t(
              'application-form:applicationForm.fields.referral'
            )} ${t('application-form:applicationForm.fields.optional')}`}
          >
            <SimpleGrid
              columns={[1, null, selectedReferral === 'other' ? 2 : 1]}
              spacing={4}
            >
              <Select
                isDisabled={Boolean(search.code)}
                placeholder={t(
                  'application-form:applicationForm.placeholders.referral'
                )}
                {...register('referral')}
              >
                {Object.entries(referralOpts).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
              {selectedReferral === 'other' && (
                <Input
                  isInvalid={errors.referralOther}
                  isReadOnly={Boolean(search.code)}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.referralOther'
                  )}
                  type="text"
                  {...register('referralOther', { required: true })}
                />
              )}
            </SimpleGrid>
          </FormField>
        </GridItem>
        <GridItem colSpan={2}>
          <FormField
            mt={0}
            name="whyAreYouInterested"
            title={t(
              'application-form:applicationForm.fields.whyAreYouInterested'
            )}
          >
            <Select
              placeholder={t(
                'application-form:applicationForm.placeholders.whyAreYouInterested'
              )}
              {...register('whyAreYouInterested', { required: true })}
            >
              {Object.entries(interestOpts).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </FormField>
        </GridItem>
      </Grid>
      {submitError && (
        <ErrorCard
          message={t('application-form:applicationForm.error')}
          mt={4}
        />
      )}
      <SubmitButton
        isSubmitting={isSubmitting}
        onSubmit={(): void => onSubmitHandler(3)}
        text={t('application-form:applicationForm.cta.submit')}
      />
      <Terms />
    </Box>
  );
};

export default Step3Form;
