import React, { useState } from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { useApplyForm } from '../../../../../hooks/datoCMS/pages/apply-form';
import { WhyApply } from '../../Details';
import SubmitButton from '../SubmitButton';
import Terms from '../Terms';
import ErrorCard from '../ErrorCard';
import StepFormProps from '../StepFormProps';
import RadioMap from '../RadioMap';
import FormField from '../FormField';
import { GoogleLogin, GoogleLoginResponse } from 'react-google-login';
import { FaGoogle } from 'react-icons/fa';

const GOOGLE_CLIENT_ID =
  '676500801588-v1fpojke5mvknngkf33c3ajcafle6kdq.apps.googleusercontent.com';

const Step1Form = ({
  onSubmitHandler,
  isSubmitting,
  submitError,
  ...props
}: StepFormProps): React.ReactElement => {
  const pageData = props.data.allDatoCmsApplyForm.nodes[0];
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const content = useApplyForm(language);
  const { register, watch, setValue } = useFormContext();

  const [selectedCourse] = watch(['course']);
  const [disableGoogleLogin, setDisableGoogleLogin] = useState(false);
  const [googleError, setGoogleError] = useState(false);

  const handleLoginSuccess = (response: GoogleLoginResponse): void => {
    setValue('email', response.profileObj.email);
    setValue('isGoogle', true);

    if (response.profileObj.givenName && response.profileObj.givenName !== '') {
      setValue('firstName', response.profileObj.givenName);
      setValue('lastName', response.profileObj.familyName);
    }

    onSubmitHandler(1);
  };

  const handleLoginError = (response: GoogleLoginResponse): void => {
    if (response.error === 'idpiframe_initialization_failed') {
      setDisableGoogleLogin(true);
    } else {
      setGoogleError(true);
    }
  };

  const courses = Object.entries(
    t('application-form:applicationForm.courseSelection.courses', {
      returnObjects: true,
    })
  );

  return (
    <Box bg="white" borderRadius="card" p={5}>
      <Text align={['left', null, null, 'center']} textStyle="title2">
        {content.featureTitle}
      </Text>
      <Text
        align={['left', null, null, 'center']}
        color="darkBlue.64"
        mt={1}
        textStyle="body5"
      >
        {content.featureSummary}
      </Text>
      <WhyApply
        color="darkBlue.64"
        display={['initial', null, 'none']}
        lang={language}
        mt={2}
        textStyle="body5"
      />
      <FormField
        name="course"
        title={t('application-form:applicationForm.courseSelection.title')}
      >
        <RadioMap
          array={courses}
          value={selectedCourse}
          {...register('course', {
            required: t('application-form:applicationForm.fieldsErrors.course'),
          })}
        />
      </FormField>
      <FormField
        name="email"
        title={t('application-form:applicationForm.fields.email')}
      >
        <Input
          placeholder={t('application-form:applicationForm.placeholders.email')}
          type="email"
          {...register('email', {
            required: t('application-form:applicationForm.fieldsErrors.email'),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('application-form:applicationForm.fieldsErrors.email'),
            },
          })}
        />
      </FormField>
      {submitError && (
        <ErrorCard
          message={t('application-form:applicationForm.error')}
          mt={4}
        />
      )}
      <SubmitButton
        isSubmitting={isSubmitting}
        onSubmit={(): void => onSubmitHandler(1)}
        text={pageData.normalFlowContinueCta}
      />
      <Text fontWeight="bold" mt={1} textAlign="center" textStyle="body5">
        {pageData.continueButtonsSeparator}
      </Text>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        cookiePolicy={'single_host_origin'}
        onFailure={handleLoginError}
        onSuccess={handleLoginSuccess}
        render={(renderProps): React.ReactElement => (
          <Button
            _disabled={{
              bg: '#E06C6333',
              _hover: { bg: '#E06C6333' },
              cursor: 'not-allowed',
            }}
            _hover={{ bg: '#E06C63' }}
            bg="#E06C63"
            disabled={
              renderProps.disabled || isSubmitting || disableGoogleLogin
            }
            iconSpacing={1.5}
            leftIcon={<FaGoogle size={24} />}
            mt={1}
            onClick={renderProps.onClick}
            w="full"
          >
            {pageData.googleFlowContinueCta}
          </Button>
        )}
      />
      {googleError && (
        <Text color="red" textAlign="center" textShadow="body6">
          {pageData.googleLoginErrorMessage}
        </Text>
      )}
      <Terms />
    </Box>
  );
};

export default Step1Form;
