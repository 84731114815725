import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Helmet from 'react-helmet';

import ApplyForm from './experiments/C';
import { Cohorts as Cohort } from './interfaces';

type ApplicationProps = PageProps<unknown, { cohorts: Cohort[] }>;

const Application = (props: ApplicationProps): React.ReactElement => (
  <>
    <Helmet>
      <script>
        {`prefinery=window.prefinery||function(){(window.prefinery.q=window.prefinery.q||[]).push(arguments)};`}
      </script>
      <script defer src="https://widget.prefinery.com/widget/v2/cm8s6zyd.js" />
    </Helmet>
    <ApplyForm {...props} />
  </>
);

export const query = graphql`
  query ($locale: String!, $datoLocale: String!, $pageName: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(b2b)|(menu)|(home)|(application-form)|(course-campus)|(course-cybersecurity-campus)|(courses-name)|(course-list)|(course-web-dev-rmt)|(breadcrumbs)|(seo)|(banner)|(covid)|(languages)/"
        }
      }
    ) {
      ...TranslationFragment
    }
    datoCmsCompanyShowcase {
      btob {
        name
        logo {
          gatsbyImageData(width: 150, layout: FULL_WIDTH)
          alt
        }
      }
    }
    allDatoCmsAssetsByPage(
      filter: { page: { eq: $pageName }, locale: { eq: $datoLocale } }
    ) {
      nodes {
        page
        locale
        assets {
          gatsbyImageData(layout: FULL_WIDTH)
          title
          alt
          customData
          basename
        }
      }
    }
    allDatoCmsApplyForm(filter: { locale: { eq: $datoLocale } }) {
      nodes {
        applyCta
        googleFlowContinueCta
        googleLoginErrorMessage
        continueButtonsSeparator
        headerImage {
          alt
          gatsbyImageData
          title
        }
        admissionsManagerMessage {
          value
        }
        admissionsManagerPicture {
          alt
          title
          gatsbyImageData
        }
        headerStat
        headerTitle
        normalFlowContinueCta
      }
    }
  }
`;

export default Application;
