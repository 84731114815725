import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'gatsby';

import IronhackLogo from '../../../components/IronhackLogo';
import { getLinkByPageName } from '../../../lib/links';

type LogoProps = {
  size: 'm' | 'xl' | 'xs' | 'l';
};

const Logo = ({ size }: LogoProps): React.ReactElement => (
  <ChakraLink as={Link} to={getLinkByPageName('home')}>
    <IronhackLogo colorScheme="electricBlue" size={size} />
  </ChakraLink>
);

export default Logo;
